.dialog-card-tag {
   max-width: 100%;
   min-height: 24px;
   padding: 4px 8px;
   border-radius: 8px;
   display: flex;
   align-items: center;
   gap: 4px;
   background-color: rgba(26, 115, 232, 0.15);
   font-size: 12px;
   line-height: 16px;
   color: rgba(26, 115, 232, 1);
}

.dialog-card-tag__name {
   flex: 1 1 auto;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.dialog-card-tag__delete-btn {
   flex: 0 0 auto;
   display: flex;
   align-items: center;
   cursor: pointer;
}

.dialog-card-tag__delete-btn:disabled {
   cursor: default;
}