.plan-page {
   flex: 1 1 auto;
   height: 100vh;
   overflow: auto;
   padding: 24px 16px 24px 32px;
}

.plan-page__container {
   position: relative;
   height: 100%;
   padding: 32px;
   border-radius: 16px;
   display: flex;
   flex-direction: column;
   gap: 32px;
   background-color: white;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
   overflow: auto;
}

@media screen and (max-width: 899.99px) {
   .plan-page {
      padding: 0px;
   }

   .plan-page__container {
      border-radius: 0;
   }
}

.plan-page__header {
   display: flex;
   gap: 16px;
}

.plan-page__title {
   font-weight: 600;
   font-size: 28px;
   line-height: 32px;
}

.plan-page__end-date {
   align-self: flex-end;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: rgba(0, 172, 239, 1);
}

@media screen and (max-width: 575.99px) {
   .plan-page__container {
      padding: 16px;
   }

   .plan-page__title {
      padding: 0 16px;
   }
}