.contact-date-picker {
   flex: 0 1 216px;
   max-width: 216px;
   position: relative;
   display: flex;
   flex-direction: column;
}

.contact-date-picker-button {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.contact-date-picker-button__label {
   font-weight: 400;
   font-size: 10px;
   line-height: 13px;
   color: rgba(140, 148, 151, 1);
}

.contact-date-picker-button__btn {
   min-height: 32px;
   padding: 4px 8px;
   display: flex;
   align-items: center;
   gap: 12px;
   border-radius: 8px;
   background-color: rgba(242, 243, 245, 1);
   cursor: pointer;
}

.contact-date-picker-button__value {
   flex: 1 1 auto;
   font-weight: 400;
   font-size: 12px;
   line-height: 14.52px;
   color: rgba(30, 34, 40, 1);
}

.contact-date-picker-button__btn-icon {
   flex: 0 0 16px;
   margin: 0;
   transition: all 0.3s ease;
}

.contact-date-picker.active .contact-date-picker-button__btn-icon {
   transform: rotate(180deg);
}

.contact-date-picker-button__date-status {
   min-width: 87px;
   min-height: 24px;
   padding: 4px 8px;
   border-radius: 24px;
   text-align: center;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   color: white;
}

.contact-date-picker-button__date-status_outdated {
   background-color: rgba(255, 85, 85, 1);
}

.contact-date-picker-button__date-status_today {
   background-color: rgba(245, 139, 63, 1);
}

.contact-date-picker-button__date-status_future {
   background-color: rgba(67, 219, 74, 1);
}

.contact-date-picker__picker-wrapper {
   display: block;
   height: 0;
}

.contact-date-picker__popup {
   position: absolute;
   z-index: 10;
   top: calc(100% + 4px);
   left: 0;
   padding: 16px;
   display: flex;
   flex-direction: column;
   border-radius: 12px;
   background-color: rgba(250, 250, 250, 1);
   box-shadow: -2px 8px 20px 0 rgba(30, 34, 40, 0.1);
   visibility: hidden;
   opacity: 0;
   transition: all 0.3s ease;
}

@media screen and (max-width: 1439.98px) {
   .contact-date-picker__popup {
      right: 0;
      left: auto;
   }
}

@media screen and (min-width: 900px) and (max-width: 1284.98px) {
   .contact-date-picker__popup {
      right: auto;
      left: 0;
   }
}

@media screen and (max-width: 767.98px) {
   .contact-date-picker__popup {
      right: auto;
      left: 0;
   }
}

.contact-date-picker.active .contact-date-picker__popup {
   visibility: visible;
   opacity: 1;
}

.contact-date-picker__picker {
   position: static !important;
   transform: none !important;
}

.contact-date-picker__picker[data-placement^=bottom] {
   padding-top: 0;
}

.contact-date-picker.active .contact-date-picker__picker {
   visibility: visible;
   opacity: 1;
}

.contact-date-picker__input {
   display: none;
}

.contact-date-picker__calendar.react-datepicker {
   border: none;
   border-radius: 12px;
   background-color: rgba(250, 250, 250, 1);
}

.contact-date-picker__calendar .react-datepicker__triangle {
   display: none;
}

.contact-date-picker__calendar .react-datepicker__navigation {
   top: -6px;
}

.contact-date-picker__calendar .react-datepicker__navigation--previous {
   left: auto;
   right: 52px;
}

.contact-date-picker__calendar .react-datepicker__navigation--next {
   right: 0px;
}

.contact-date-picker__calendar .react-datepicker__navigation-icon::before {
   width: 10px;
   height: 10px;
   border-width: 1px 1px 0 0;
   border-color: rgba(0, 172, 239, 1);
}

.contact-date-picker__calendar .react-datepicker__month-container {
   display: flex;
   flex-direction: column;
   float: none;
}

.contact-date-picker__calendar .react-datepicker__header {
   padding: 0;
   border: none;
   background-color: transparent;
}

.contact-date-picker__calendar .react-datepicker__current-month {
   padding-bottom: 16px;
   font-weight: 500;
   font-size: 14px;
   line-height: 16.94px;
   text-align: left;
}

.contact-date-picker__calendar .react-datepicker__current-month::first-letter {
   text-transform: uppercase;
}

.contact-date-picker__calendar .react-datepicker__day-names {
   margin: 12px 0;
   display: flex;
   justify-content: space-between;
   gap: 12px;
}

.contact-date-picker__calendar .react-datepicker__day-name,
.contact-date-picker__calendar .react-datepicker__day {
   flex: 0 0 32px;
   width: 32px;
   height: 32px;
   margin: 0;
   border: 1px solid transparent;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 14px;
   line-height: 14px;
}

.contact-date-picker__calendar .react-datepicker__day-name {
   height: 20px;
}

.contact-date-picker__calendar .react-datepicker__day:hover {
   border-radius: 8px;
   border-color: rgba(93, 96, 112, 1);
   background-color: transparent;
}

.contact-date-picker__calendar .react-datepicker__day.react-datepicker__day--disabled:hover {
   border-color: transparent;
}

.contact-date-picker__calendar .react-datepicker__day--selected, 
.contact-date-picker__calendar .react-datepicker__day--selected:hover {
   border-radius: 8px;
   border-color: rgba(0, 172, 239, 1);
   background-color: rgba(0, 172, 239, 1);
   color: white;
}
.contact-date-picker__calendar .react-datepicker__day--keyboard-selected {
   background: none;
}

.contact-date-picker__calendar .react-datepicker__day-names .react-datepicker__day-name {
   font-size: 12px;
   line-height: 16px;
   color: rgba(93, 96, 112, 1);
}

.contact-date-picker__calendar .react-datepicker__month {
   margin: 0;
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.contact-date-picker__calendar .react-datepicker__week {
   display: flex;
   justify-content: space-between;
   gap: 12px;
}

.contact-date-picker__actions {
   padding-top: 16px;
   display: flex;
   gap: 16px;
}

.contact-date-picker__action-btn {
   flex: 1 1 50%;
}