.range-input {
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.range-input.disabled {
   pointer-events: none;
}

.range-input__wrapper {
   position: relative;
}

.range-input__input {
   appearance: none;
   outline: none;
   width: 100%;
   height: 12px;
   border-radius: 20px;
   background-color: #F8F8F8;
}

.range-input.disabled .range-input__input {
   background-color: rgba(249, 249, 249, 1);
}

.range-input__input::-webkit-slider-thumb {
   appearance: none;
   width: 24px;
   height: 24px;
   border: 6px solid white;
   border-radius: 50%;
   background-color: #40B7D9;
   box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
   cursor: pointer;
   position: relative;
   z-index: 5;
}

.range-input.disabled .range-input__input::-webkit-slider-thumb {
   background-color: rgba(216, 217, 223, 1);
}

.range-input__progress {
   position: absolute;
   top: 50%;
   left: 0;
   pointer-events: none;
   transform: translateY(-50%);
   width: 50%;
   height: 12px;
   border-radius: 20px;
   background-color: #00ACEF;
}

.range-input.disabled .range-input__progress {
   background-color: rgba(216, 217, 223, 1);
}

.range-input__footer {
   padding: 0 16px;
   display: flex;
   justify-content: space-between;
   gap: 24px;
}

.range-input__footer span {
   font-size: 20px;
   line-height: 1.2;
}

.range-input.disabled .range-input__footer span {
   color: rgba(216, 217, 223, 1);
}