.manager-card {
   padding: 7px 12px;
   border: 1px solid transparent;
   border-radius: 12px;
   display: flex;
   align-items: center;
   gap: 12px;
   background-color: white;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
   cursor: pointer;
   transition: all 0.2s ease;
}

.manager-card.selected,
.manager-card:hover {
   border-color: rgba(0, 172, 239, 1);
   background-color: rgba(240, 251, 255, 1);
}

.manager-card__avatar {
   flex: 0 0 32px;
   position: relative;
   height: 32px;
   border-radius: 50%;
   overflow: hidden;
}

.manager-card__photo {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   object-fit: cover;
}

.manager-card__info {
   flex: 1 1 auto;
   overflow: hidden;
}

.manager-card__name {
   font-weight: 600;
   font-size: 16px;
   line-height: 20px;
   color: rgba(30, 34, 40, 1);
}

.manager-card__email {
   font-size: 14px;
   line-height: 20px;
   color: rgba(93, 96, 112, 1);
}

.manager-card__name,
.manager-card__email {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.manager-card__status {
   align-self: flex-start;
   flex: 0 0 20px;
   height: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.manager-card__status::before {
   content: "";
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background-color: rgba(255, 85, 85, 1);
}

.manager-card__status_active::before {
   background-color: rgba(67, 219, 74, 1);
}