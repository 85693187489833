.card-avatar {
   flex: 0 0 32px;
   width: 32px;
   height: 32px;
   position: relative;
}

.card-avatar__photo {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   object-fit: cover;
}

.card-avatar__channel-icon {
   position: absolute;
   bottom: 0;
   right: 0;
   width: 12px;
   height: 12px;
}