.plan-error {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 20px;
}

.plan-error__text {
   font-size: 20px;
}