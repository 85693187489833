.warning-message {
   padding: 12px;
   border-radius: 12px;
   display: flex;
   align-items: flex-start;
   gap: 12px;
   background-color: #FFD675;
}

.warning-message__icon {
   flex: 0 0 22px;
}

.warning-message__body {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.warning-message__body h4 {
   font-weight: 700;
   font-size: 14px;
   line-height: 18px;
}

.warning-message__body p {
   font-size: 12px;
   line-height: 1.4;
}

.warning-message__close-btn {
   flex: 0 0 22px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}