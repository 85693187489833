.edit-contact-form {
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.edit-contact-form__body {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.edit-contact-form-control {
   margin-bottom: 0;
   padding: 4px 12px;
   border-radius: 12px;
   background-color: rgba(242, 243, 245, 1);
}

.edit-contact-form-control .add-contact-form-control-label {
   padding: 0;
   font-size: 12px;
   line-height: 16px;
   color: rgba(93, 96, 112, 1);
}

.edit-contact-form-control .add-contact-form-control-input,
.edit-contact-form-control .add-contact-form-control-input-red,
.edit-contact-form-control .add-contact-form-control-input:focus {
   min-width: auto;
   height: auto;
   padding: 0;
   border: none;
   border-radius: 0;
   background-color: transparent;
   text-indent: 0;
   font-size: 16px;
   line-height: 24px;
   color: rgba(30, 34, 40, 1);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.edit-contact-form__footer {
   flex-direction: row;
   justify-content: flex-end;
   gap: 20px;
}

.edit-contact-form__btn {
   min-height: 48px;
   border-radius: 12px;
   box-shadow: none;
   font-size: 16px;
   line-height: 24px;
}