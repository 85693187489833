.incorrect-numbers-modal {
   position: fixed;
   z-index: 100;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.2);
   display: flex;
   justify-content: center;
   align-items: center;
   visibility: hidden;
   opacity: 0;
   transition: all 0.3s ease;
}

.incorrect-numbers-modal.active {
   visibility: visible;
   opacity: 1;
}

.incorrect-numbers-modal__body {
   max-width: 460px;
   padding: 40px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   gap: 16px;
   background-color: white;
}

.incorrect-numbers-modal__header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
}

.incorrect-numbers-modal__title {
   font-weight: 600;
   font-size: 20px;
   line-height: 1.2;
}

.incorrect-numbers-modal__close-btn {
   flex: 0 0 24px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

.incorrect-numbers-modal__close-btn img {
   margin: 0;
}

.incorrect-numbers-modal__text {
   font-size: 14px;
   line-height: 1.285;
}

.incorrect-numbers-modal__numbers-list-container {
   padding: 12px 0;
}

.incorrect-numbers-modal__numbers-list-title {
   font-size: 12px;
   line-height: 1.3;
   margin-bottom: 8px;
}

.incorrect-numbers-modal__numbers-list {
   display: flex;
   flex-wrap: wrap;
   gap: 12px;
}

.incorrect-numbers-modal__numbers-list-item {
   min-width: 182px;
   padding: 8px;
   border-radius: 12px;
   display: flex;
   align-items: center;
   gap: 12px;
   background-color: #F8F8F8;
   font-size: 16px;
   line-height: 1.25;
}

.incorrect-numbers-modal__numbers-list-item img {
   margin: 0;
   flex: 0 0 20px;
}

.incorrect-numbers-modal__footer {
   display: flex;
   flex-direction: column;
   gap: 12px;
}