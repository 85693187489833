.cart-support-block {
   gap: 12px;
}

.cart-support-block-header {
   padding-bottom: 12px;
   border-bottom: 1px solid #E4E4E4;
}

.cart-support-block-header__title {
   font-weight: 700;
   font-size: 14px;
   line-height: 1.285;
}

.cart-support-block-body {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.cart-support-block-body__toggle {
   min-height: 42px;
   padding: 12px 20px;
   border: 1px solid #00ACEF;
   border-radius: 8px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
   font-weight: 500;
   font-size: 14px;
   line-height: 1.285;
   color: #00ACEF;
   cursor: pointer;
}

.cart-support-block-body__toggle svg {
   width: 11px;
   transform: rotate(180deg);
   transition: all 0.3s ease;
}

.cart-support-block-body.active .cart-support-block-body__toggle svg {
   transform: rotate(0);
}

.cart-support-block-body__toggle svg path {
   width: 11px;
   fill: #00ACEF;
}

.cart-support-block-body__list {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.cart-support-block-body__item {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
}

.cart-support-block-body__item_phones .cart-support-block-body__item-name {
   align-self: flex-start;
}

.cart-support-block-body__item-name,
.cart-support-block-body__item-link {
   font-size: 14;
   line-height: 1.285;
}

.cart-support-block-body__item-link {
   color: #00ACEF;
}

.cart-support-block-body__item-phones-list {
   display: flex;
   flex-direction: column;
}

.cart-support-block-body__item-phones-list-item {

}