.contact-info-item {
   padding: 4px 12px;
   border-radius: 12px;
   background-color: rgba(242, 243, 245, 1);
}

.contact-info-item__label {
   font-size: 12px;
   line-height: 16px;
   color: rgba(93, 96, 112, 1);
}

.contact-info-item__value {
   font-size: 16px;
   line-height: 24px;
   color: rgba(30, 34, 40, 1);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}