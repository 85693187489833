.cart {
   gap: 12px;
}

.cart__loader {
   min-height: 200px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.cart-header {
   padding-bottom: 12px;
   border-bottom: 1px solid #E4E4E4;
   display: flex;
   flex-direction: column;
}

.cart-header__text {
   font-size: 12px;
   line-height: 1.3;
}

.cart-header__price,
.cart-header__empty-message {
   font-weight: 700;
   font-size: 20px;
   line-height: 1.2;
   color: rgba(255, 94, 45, 1);
}

.cart-header__empty-message {
   color: #8C9497;
}

.cart-body {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.cart-body__item {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
}

.cart-body__item-name {
   display: flex;
   align-items: center;
   gap: 4px;
}

.cart-body__item-name,
.cart-body__item-price {
   font-size: 14;
   line-height: 1.285;
}

.cart-body__item-name-quantity {
   color: #8C9497;
}

.cart-body__item-tooltip {
   margin-left: 4px;
}

.cart-body__item-tooltip .plan-tooptip__body {
   left: auto;
   right: 0;
   width: 258px;
}

.cart__fallback-text {
   font-size: 14px;
   line-height: 20px;
   color: rgba(30, 34, 40, 1);
}
