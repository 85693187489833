.mainbar-sidebar-toggle-left-button {
   position: absolute;
   top: 24px;
   left: 5px;
   z-index: 101;
   height: 25px;
   width: 25px;
   display: none;
}

.mainbar-sidebar-toggle-left-button_show {
   display: block;
}