.tag-select {
   position: relative;
}

.tag-select__selected-value {
   width: 100%;
   min-height: 32px;
   min-width: 186px;
   padding: 4px 8px;
   border-radius: 8px;
   display: flex;
   align-items: center;
   gap: 8px;
   background-color: rgba(242, 243, 245, 1);
   font-size: 14px;
   line-height: 20px;
   color: rgba(30, 34, 40, 1);
   cursor: pointer;
   overflow: hidden;
   transition: all 0.2s ease;
}

.tag-select__selected-value-item {
   max-width: 150px;
   padding: 4px 8px;
   border-radius: 8px;
   display: flex;
   align-items: center;
   gap: 8px;
   background-color: rgba(228, 229, 231, 1);
}

.tag-select__selected-value-item-text {
   font-size: 12px;
   line-height: 16px;
   color: rgba(30, 34, 40, 1);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.tag-select__selected-value-item-delete-btn {
   display: flex;
   align-items: center;
   cursor: pointer;
}

.tag-select__selected-value-item-delete-btn-icon {
   width: 14px;
   max-width: none;
}

.tag-select__icon {
   position: absolute;
   right: 0;
   top: 0;
   width: 38px;
   height: 100%;
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(242, 243, 245, 1);
   box-shadow: -4px 0 8px rgba(255, 255, 255, 0.5);
}

.tag-select__icon svg {
   width: 16px;
   height: 8px;
   transform: rotate(180deg);
   transition: all 0.2s ease;
}

.tag-select.active .tag-select__icon svg {
   transform: rotate(0);
}

.tag-select__icon svg path {
   fill: rgba(93, 96, 112, 1);
}

.tag-select__popup {
   position: absolute;
   z-index: 10;
   top: calc(100% + 4px);
   left: 0;
   min-width: 100%;
   max-width: 250px;
   padding: 8px;
   border-radius: 12px;
   background-color: white;
   box-shadow: -2px 8px 20px 0 rgba(30, 34, 40, 0.1);
   visibility: hidden;
   opacity: 0;
   transition: all 0.2s ease;
}

.tag-select__search .sidebar-header-search-field {
   min-height: 32px;
   padding: 6px 8px;
   border-radius: 8px;
}

.tag-select.active .tag-select__popup {
   visibility: visible;
   opacity: 1;
}

.tag-select__list-wrapper {
   display: flex;
   flex-direction: column;
}

.tag-select__list-wrapper:not(:last-child) {
   padding-bottom: 13px;
   border-bottom: 1px solid rgba(209, 210, 214, 1);
}

.tag-select__list-title {
   padding: 12px 12px 4px;
   font-size: 12px;
   line-height: 16px;
   color: rgba(93, 96, 112, 1);
}

.tag-select__list {
   list-style: none;
   max-height: 216px;
   overflow-y: auto;
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.tag-select__list_selected {
   max-height: 128px;
}

.tag-select__option {
   width: 100%;
   min-height: 40px;
   padding: 8px 12px;
   display: flex;
   align-items: center;
   gap: 12px;
   transition: all 0.2s ease;
   cursor: pointer;
   color: rgba(30, 34, 40, 1);
}

.tag-select__option:disabled {
   cursor: default;
}

.tag-select__option-text {
   font-size: 16px;
   line-height: 24px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.tag-select__empty-list-message {
   padding: 5px;
}
