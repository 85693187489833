.sidebar-header-search-container {
   width: 100%;
}

.sidebar-header-search-field {
   min-height: 48px;
   padding: 12px;
   display: flex;
   align-items: center;
   gap: 12px;
   border-radius: 12px;
   background-color: rgba(242, 243, 245, 1);
}

.sidebar-header-search-field-input {
   flex: 1 1 auto;
   width: 100%;
   background-color: transparent;
   outline: none;
   border: none;
}

.sidebar-header-search-field-input:placeholder-shown {
   font-size: 14px;
}