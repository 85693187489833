.managers-constructor {
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.managers-constructor__header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 16px;
}

.managers-constructor__name {
   font-weight: 400;
   font-size: 20px;
   line-height: 1.2;
}

.managers-constructor__tooltip {
   margin-right: auto;
}

.managers-constructor__remaining-managers-status {
   font-size: 14px;
   line-height: 1.28;
   color: #8C9497;
}

.managers-constructor__remaining-managers-status span {
   font-weight: 500;
   font-size: 16px;
   line-height: 1.25;
   color: #252525;
}

.managers-constructor.disabled .managers-constructor__name,
.managers-constructor.disabled .managers-constructor__remaining-managers-status,
.managers-constructor.disabled .managers-constructor__remaining-managers-status span {
   color: rgba(216, 217, 223, 1);
}