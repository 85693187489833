.plan-item {
   position: relative;
}

.plan-item-toggle {
   width: 100%;
   min-width: 290px;
   padding: 12px 24px;
   border: 1px solid #F8F8F8;
   border-radius: 12px;
   display: flex;
   align-items: center;
   gap: 16px;
   background-color: #F8F8F8;
   cursor: pointer;
}

.plan-item.active .plan-item-toggle,
.plan-item.selected .plan-item-toggle {
   border-color: #00ACEF;
}

.plan-item-toggle__name,
.plan-item-toggle__price {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   font-size: 12px;
   line-height: 1.3;
}

.plan-item-toggle__name span,
.plan-item-toggle__price span {
   font-size: 16px;
   line-height: 1.25;
}

.plan-item-toggle__price {
   padding-left: 16px;
   border-left: 1px solid #E4E4E4;
}

.plan-item-toggle__icon {
   margin: 0;
   margin-left: auto;
   width: 14px;
   transform: rotate(180deg);
   transition: 0.3s ease;
}

.plan-item.active .plan-item-toggle__icon {
   transform: rotate(0);
}

.plan-item-drop-down {
   position: absolute;
   z-index: 10;
   top: calc(100% + 4px);
   left: 0;
   width: 100%;
   padding: 12px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   gap: 16px;
   background-color: white;
   box-shadow: 2px 8px 24px 0 rgb(0, 0, 0, 0.12);
   opacity: 0;
   visibility: hidden;
   transition: all 0.3s ease;
}

.plan-item.active .plan-item-drop-down {
   opacity: 1;
   visibility: visible;
}

.plan-item-drop-down__list {
   list-style: disc;
   padding-left: 16px;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.plan-item-drop-down__list-item {
   list-style: disc;
   font-size: 12px;
   line-height: 1.4;
}

.plan-item-drop-down__list-item:first-child {
   color: rgba(255, 94, 45, 1);
}

.plan-item-drop-down__add-btn img {
   margin: 0;
}