hr {
   opacity: 0.5;
}

.manager-mainbar-wrapper {
   height: 100vh;
   padding: 24px 32px;
   width: 100%;
   border-radius: 6px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   box-shadow: 0px 6px 30px rgba(213, 230, 235, 0.4);
   overflow: hidden;
   overflow-y: auto;
}

.manager-mainbar-wrapper::-webkit-scrollbar {
   display: block;
   width: 4px;
}

.manager-mainbar-user {
   display: flex;
   align-items: center;
   gap: 24px;
}

.manager-mainbar-user__avatar {
   flex: 0 0 56px;
   position: relative;
   height: 56px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.manager-mainbar-user-photo {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   object-fit: cover;
   margin: 0;
}

.manager-edit-photo-button {
   position: absolute;
   width: 24px;
   height: 24px;
   min-height: auto;
   padding: 0;
   bottom: 0;
   right: 0;
   border-radius: 9px;
   cursor: pointer;
}

.manager-mainbar-user__info {
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.manager-mainbar-user_name {
   font-style: normal;
   font-weight: 700;
   font-size: 20px;
   line-height: 28px;
}

.manager-mainbar-user__status {
   align-self: flex-start;
   padding: 4px 8px;
   border-radius: 8px;
   background-color: #FFCCCC;
   font-size: 12px;
   line-height: 16px;
   color: #ED5656;
   text-align: center;
}

.manager-mainbar-user__status_active {
   background-color: #C8FFCA;
   color: #43DB4A;
}

.mainbar-add-manager-title {
   font-weight: 800;
   font-size: 18px;
   line-height: 25px;
   text-align: left;
   color: #000000;
   margin-bottom: 36px;
}

.manager-mainbar-page-block {
   position: relative;
   min-height: 40px;
   border-bottom: 1px solid rgba(209, 210, 214, 1);
}

.manager-mainbar-page-block__container {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   display: flex;
   gap: 8px;
}

.manager-mainbar-page-button {
   min-height: 40px;
   padding: 8px 12px;
   border-bottom: 2px solid transparent;
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   color: rgba(93, 96, 112, 1);
   cursor: pointer;
   transition: all 0.2s ease;
}

.manager-mainbar-page-button.active {
   color: rgba(255, 94, 45, 1);
   border-color: rgba(255, 94, 45, 1);
}

.manager-mainbar-info-wrapper {}

.mainbar-add-manager-control-form {
   width: 100%;
   margin-bottom: 22px;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.mainbar-add-manager-control-form:last-child {
   margin-bottom: 40px;
}

.mainbar-add-manager-control-form label {
   padding-right: 36px;
   display: inline-block;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
}

.mainbar-add-manager-control-form input {
   padding-left: 20px;
   background: #ffffff;
   border: 1px solid #e4e4e4;
   box-sizing: border-box;
   border-radius: 6px;
   width: 360px;
   height: 38px;
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
   display: inline;
}

.mainbar-add-manager-control-form-container {
   display: flex;
   align-items: center;
   gap: 10px;
}

.mainbar-add-manager-control-form-container .mainbar-add-manager-control-form {
   width: auto;
}

.mainbar-manager-button-block {
   display: flex;
   flex-direction: column;
   gap: 15px;
   align-items: flex-start;
}

.mainbar-add-manager-form-button {
   padding: 14px 26px;
   border-radius: 4px;
   background: #00acef;
   border: 1px solid #00acef;
   color: white;
   font-size: 16px;
   line-height: 21px;
   cursor: pointer;
   transition: all 0.3s ease;
}

.show-password-btn {
   cursor: pointer;
}

.show-password-btn img {
   opacity: 0.5;
}

.show-password-btn_active img {
   opacity: 1;
}

.mainbar-add-manager-form-button:hover {
   background: #00a3e3;
}

.edit-btn {
   display: flex;
   align-items: center;
   gap: 5px;
}

.manager-permission-control {
   flex: 1 1 auto;
   overflow-y: auto;
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.manager-permission-control::-webkit-scrollbar {
   display: block;
   width: 4px;
}

.manager-permission-control::-webkit-scrollbar-track{
   border-radius: 2px;
}

.manager-permission-control::-webkit-scrollbar-thumb{
   border-radius: 2px;
}

.manager-permission-control_list-block {
   flex: 1 1 auto;
   max-height: 516px;
}

.manager-permission-control_list-block-container{
   flex: 1 1 auto;
   display: flex;
   align-items: stretch;
   gap: 24px;
   overflow: hidden;
   overflow-x: auto;
}

.manager-permission-control_list-block-container::-webkit-scrollbar {
   display: block;
   height: 4px;
}

.manager-permission-control_list {
   width: 100%;
   min-width: 230px;
   padding: 16px 24px 16px 0;
   border-right: 1px solid rgba(209, 210, 214, 1);
   display: flex;
   flex-direction: column;
   gap: 24px;
   position: relative;
}

.manager-permission-control_list:last-child {
   padding-right: 0;
   border-right-color: transparent;
}

.manager-permission_list {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.manager-permission-control-field {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 8px;
}

.manager-permission-control-field-name {
   font-size: 20px;
   line-height: 28px;
   color: rgba(30, 34, 40, 1);
}

.manager-permission-controller {
   flex: 0 0 48px;
   height: 24px;
   padding: 2px;
   background-color: #E8E8E8;
   border-radius: 24px;
   display: flex;
   align-items: center;
   cursor: pointer;
}

.manager-permission-controller.active {
   background-color: rgba(0, 172, 239, 1);
}

.manager-permission-field-name {
   flex: 1 1 auto;
   font-size: 16px;
   line-height: 24px;
   color: rgba(30, 34, 40, 1);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.manager-permission-field-name_active {
   background-color: rgba(222, 248, 255, 1);
}

.manager-permission-toggle-button {
   margin-right: auto;
   background: white;
   width: 20px;
   height: 20px;
   border-radius: 50%;
}

.manager-permission-toggle-button_active {
   margin-right: 0;
   margin-left: auto;
}

.manager-permission__footer-permissions {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.manager-permission-control-dialogs-permission {
   max-width: 565px;
   display: flex;
   align-items: center;
   padding: 8px 12px;
   gap: 8px;
}

.manager-permission-control-text {
   flex: 1 1 auto;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 140%;
}

.manager-permission-pagination {
   display: flex;
   justify-content: center;
}
