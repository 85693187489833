.attachments-box {
   position: relative;
}

.attachments-box__add-btn {
   cursor: pointer;
   display: inline;
   position: absolute;
   top: 16px;
   left: 12px;
   z-index: 999;
   display: flex;
   align-items: center;
}

.attachments-box__add-btn svg path {
   fill: rgba(93, 96, 112, 1);
}

.attachments-box__add-btn:disabled {
   opacity: .2;
}

.attachments-add-btn__input {
   display: none;
}

.file-quantity {
   position: absolute;
   top: 12px;
   left: 6px;
   background-color: #4bade9;
   border-radius: 50%;
   height: 15px;
   width: 15px;
   text-align: center;
   display: flex;
   justify-content: center;
   align-content: center;
}

.file-quantity_number {
   font-size: 10px;
   color: white;
}

.attachments-show-popup-btn:hover {
   cursor: pointer;
}

.attachments-show-popup-btn svg path {
   fill: rgba(93, 96, 112, 1);
}

.attachments-popup {
   position: absolute;
   bottom: 100%;
   transform: translateY(-15px);
   display: flex;
   gap: 10px;
   flex-direction: column;
   width: 250px;
   padding: 15px;
   border-radius: 5px;
   background-color: #fff;
   visibility: hidden;
   opacity: 0;
   -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
   -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
   box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2);
   transition: all 0.3s ease;
}

.attachments-popup_active {
   visibility: visible;
   opacity: 1;
   transform: translateY(-10px);
}

.attachments-popup__list {
   display: flex;
   flex-direction: column;
   gap: 10px;
   max-height: 150px;
   overflow-y: auto;
}

.attachments-popup__list-item {
   display: flex;
   justify-content: space-between;
   gap: 10px;
}

.attachments-popup__filename {
   font-size: 14px;
   text-overflow: ellipsis;
   overflow: hidden;
}

.attachments-popup__delete-btn {
   align-self: flex-start;
   margin-top: 2px;
}

.attachments-popup__delete-btn:hover {
   cursor: pointer;
}

.attachments-popup__delete-btn svg {
   fill: gray;
   transition: fill 0.3s ease;
}

.attachments-popup__delete-btn:hover svg {
   fill: red;
}

.attachments-popup__actions {
   display: flex;
   justify-content: space-between;
}

.attachments-add-more-btn {
   padding: 5px 0;
}

.attachments-add-more-btn:hover {
   cursor: pointer;
}

.attachments-add-more-btn__text {
   font-size: 14px;
   line-height: 1.5;
   color: #4bade9;
}

.attachments-add-more-btn:hover .attachments-add-more-btn__text {
   text-decoration: underline;
}

.attachments-add-more-btn__input {
   display: none;
}

.attachments-popup__close-btn {
   padding: 5px 0;
   font-size: 14px;
   line-height: 1.5;
   color: #4bade9;
}

.attachments-popup__close-btn:hover {
   cursor: pointer;
   text-decoration: underline;
}

button {
   border: none;
   background-color: transparent;
}