.plan {
   display: flex;
   gap: 40px;
}

.plan__main {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
   gap: 60px;
}

.plan__plan-list {
   display: flex;
   gap: 20px;
   flex-wrap: wrap;
}

.plan__plan-components-list {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.plan__sidebar {
   flex: 0 0 292px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}

@media screen and (max-width: 767.99px) {
   .plan {
      flex-direction: column;
   }
}

@media screen and (max-width: 575.99px) {
   .plan__tariff-list {
      flex-direction: column;
   }
}