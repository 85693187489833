.period-select {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.period-select__label {
   font-weight: 400;
   font-style: 14px;
   line-height: 14px;
   color: rgba(132, 131, 154, 1);
}

.period-select__select {
   position: relative;
}

.period-select__selected-value {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 5px;
   max-height: 48px;
   padding: 4px 12px;
   border-radius: 12px;
   background-color: rgba(242, 243, 245, 1);
   cursor: pointer;
}

.period-select__selected-value-container {
   display: flex;
   flex-direction: column;
}

.period-select__label {
   font-size: 12px;
   line-height: 16px;
   color: rgba(93, 96, 112, 1);
   text-align: left;
}

.period-select__selected-value-text {
   font-size: 16px;
   line-height: 24px;
   color: rgba(30, 34, 40, 1);
}

/* .period-select__selected-value:hover {
   background-color: rgba(221, 226, 253, 1);
} */

/* .period-select.active .period-select__selected-value {
   border-radius: 12px 12px 0 0;
} */

.period-select__selected-value svg {
   transition: 0.2s ease;
}

.select.active .period-select__selected-value svg {
   transform: rotate(-180deg);
}

.period-select__selected-value path {
   stroke: rgba(93, 96, 112, 1);
}

.period-select__popup {
   position: absolute;
   z-index: 10;
   left: 0;
   top: calc(100% + 4px);
   width: 100%;
   padding: 8px;
   border-radius: 12px;
   background-color: white;
   box-shadow: -2px 8px 20px 0 rgba(30, 34, 40, 0.1);
   overflow-x: hidden;
   visibility: hidden;
   opacity: 0;
}

.period-select.active .period-select__popup {
   visibility: visible;
   opacity: 1;
}

.period-select__list {
   max-height: 232px;
   display: flex;
   flex-direction: column;
   gap: 4px;
   overflow-y: auto;
}

.period-select__list::-webkit-scrollbar {
   display: block;
   width: 4px;
}

.period-select__list::-webkit-scrollbar-track {
   background-color: rgba(240, 240, 242, 1);
   border-radius: 2px;
}

.period-select__list::-webkit-scrollbar-thumb {
   background-color: rgb(219, 219, 219);
   border-radius: 4px;
}

.period-select__list-item {
   padding: 8px 12px;
   border-radius: 12px;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: rgba(30, 34, 40, 1);
   cursor: pointer;
   transition: all 0.3s ease;
}

/* .period-select__list-item:not(:first-child) {
   border-top: 1px solid rgba(217, 220, 231, 1);
} */

.period-select__list-item:hover,
.period-select__list-item.selected {
   background-color: rgba(243, 243, 245, 1);
}