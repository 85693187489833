.plan-tooptip {
   position: relative;
   display: flex;
   align-items: center;
   cursor: pointer;
}

.plan-tooptip__body {
   position: absolute;
   z-index: 10;
   top: calc(100% + 4px);
   left: 0;
   width: 235px;
   padding: 8px;
   border-radius: 8px;
   background-color: rgba(30, 34, 40, 1);
   font-size: 12px;
   line-height: 16px;
   color: white;
   visibility: hidden;
   opacity: 0;
   pointer-events: none;
   transition: all 0.2s ease;
}

.plan-tooptip:hover .plan-tooptip__body {
   visibility: visible;
   opacity: 1;
}