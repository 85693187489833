.sidebar-active {
   width: 100%;
   flex: 1 1 auto;
   max-width: 440px;
   padding: 24px 32px;
   display: flex;
   flex-direction: column;
   height: 100vh;
   z-index: 10;
   position: relative;
}

.sidebar-inactive {
   display: none;
   width: 0px !important;
   height: 100vh;
   margin-left: 64px;
   z-index: 100;
   position: relative;
}

.sidebar-container {
   height: 100%;
   padding: 16px;
   padding-left: 24px;
   border-radius: 16px;
   display: flex;
   flex-direction: column;
   background-color: white;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
}

.sidebar-wrapper {
   display: flex;
   flex-direction: column;
   margin-bottom: 24px;
}

.sidebar-view-toggle-active {
   position: absolute;
   right: -12px;
   top: 24px;
   width: 25px;
   height: 25px;
   border-radius: 50%;
   background: #00acef;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-right: 2px;
   cursor: pointer;
   transition: transform 0.3s linear;
}

.sidebar-view-toggle-inactive {
   background-color: #e4e6ec;
   position: absolute;
   right: -12px;
   top: 24px;
   width: 25px;
   height: 25px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-left: 2px;
   cursor: pointer;
   transition: transform 0.3s linear;
}

.sidebar-view-toggle:hover {
   transform: scale(1.08);
}

.sidebar-header-dropdown {
   margin-bottom: 24px;
}

.sidebar-header-dropdown-menu__control {
   width: 100%;
}

.sidebar-header-dropdown-menu__menu {
   width: 100% !important;
   border: none !important;
}

.sidebar-header-dropdown-menu__control {
   height: 30px;
   border-radius: 6px !important;
   border: none;
   outline: none !important;
   border-color: #ffffff !important;
   box-shadow: none !important;
}

.sidebar-header-dropdown-menu__indicator-separator {
   background-color: #ffffff !important;
}

.sidebar-header-dropdown-menu__placeholder {
   font-size: 14px;
   text-indent: 10px;
}

.sidebar-header-profile {
   flex: 1 1 auto;
   width: 100%;
   display: flex;
   align-items: center;
}

.sidebar-header-profile-pic {
   flex: 1 1 auto;
   display: flex;
   justify-content: flex-start;
   flex-direction: row;
   align-items: center;
}

.sidebar-header-settings {
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.sidebar-header-profile-display {
   flex: 1 1 auto;
   display: flex;
   flex-wrap: wrap;
   gap: 5px;
   align-items: center;
   justify-content: space-between;
}

.sidebar-header-profile-name {
   font-size: 28px;
   line-height: 36px;
   font-weight: 700;
   cursor: default;
   font-family: inherit;
   text-transform: capitalize;
   color: #252525;
}

.sidebar-header-load-contacts-btn {
   padding: 5px 10px;
   box-shadow: none;
}

.sidebar-header-load-contacts-btn svg {
   fill: rgba(0, 172, 239, 1);
}

.sidebar-header-load-contacts-btn:hover svg {
   fill: rgba(54, 194, 249, 1);
}

.sidebar-header-load-contacts-btn:active svg {
   fill: rgba(21, 167, 224, 1);
}

.sidebar-header-load-contacts-btn:disabled svg {
   fill: rgba(197, 199, 208, 1);
}

.load-contacts-image {
   fill: #4bade9;
}

.load-contacts-ref {
   text-decoration: none;
   color: rgba(0, 172, 239, 1);
}

.sidebar-header-load-contacts-btn:hover .load-contacts-ref {
   color: rgba(54, 194, 249, 1);
}

.sidebar-header-load-contacts-btn:active .load-contacts-ref {
   color: rgba(21, 167, 224, 1);
}

.sidebar-header-load-contacts-btn:disabled .load-contacts-ref {
   color: rgba(197, 199, 208, 1);
}

.sidebar-header-logout-link {
   font-size: 12px;
   color: #007bff;
   line-height: 16px;
   font-weight: 400;
   cursor: pointer;
}

.sidebar-view-toggle-buttons {
   height: 58px;
   display: grid;
   grid-template-columns: repeat(2, auto);
}

.sidebar-view-dialogs,
.sidebar-view-contacts {
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   text-transform: uppercase;
   font-weight: 800;
   color: black;
   font-size: 15px;
   font-family: 'Open Sans';
   z-index: 999;
}

.sidebar-view-dialogs {
   border-radius: 0px 30px 0px 0px;
}

.sidebar-view-contacts {
   border-radius: 30px 0px 0px 0px;
}

.sidebar-list {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
   gap: 16px;
   margin-bottom: 16px;
}

.contacts-sidebar-filter {
   margin-bottom: 16px;
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.contacts-sidebar-btn {
   min-height: 56px;
   border-radius: 16px;
}

@media screen and (max-width: 767.99px) {
   .contacts-sidebar-btn {
      min-height: 48px;
   }
}

@media screen and (max-width: 1023.99px) {
   .sidebar-active {
      max-width: 360px;
      padding: 24px 16px;
   }
}

@media screen and (max-width: 899.98px) {
   .sidebar-active {
      width: calc(100% - 194px);
      max-width: none;
   }

   .sidebar-container {
      padding: 24px 16px;
   }
}

@media screen and (max-width: 575.98px) {
   .sidebar-active {
      width: calc(100% - 66px);
      max-width: none;
      padding: 12px 8px;
   }
}

@media screen and (max-width: 425.98px) {
   .sidebar-active {
      padding: 0;
      border-radius: 0;
   }

   .sidebar-container {
      padding: 24px 16px 0;
      border-radius: 0;
   }
}