.input {
   padding: 4px 12px;
   border-radius: 12px;
   background-color: rgba(242, 243, 245, 1);
}

.input__container {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
}

.input_text {
   display: flex;
   flex-direction: column;
}

.input_password {
   display: flex;
   align-items: center;
   gap: 12px;
}

.input__label {
   font-size: 12px;
   line-height: 16px;
   color: rgba(93, 96, 112, 1);
}

.input_error .input__label {
   color: rgba(255, 85, 85, 1);
}

.input__input {
   width: 100%;
   min-height: 24px;
   border: none;
   background-color: transparent;
   font-size: 16px;
   line-height: 24px;
   color: rgba(30, 34, 40, 1);
   outline: none;
}

.input__input::placeholder {
   font-size: 16px;
   line-height: 24px;
   color: rgba(30, 34, 40, 1);
}

.input__toggle-password-btn {
   flex: 0 0 20px;
   height: 20px;
   cursor: pointer;
}

.input__toggle-password-icon {
   width: 100%;
}