.available-managers-status-bar {
   padding: 12px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   gap: 16px;
   background-color: white;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
}

.available-managers-status-bar__header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 20px;
}

.available-managers-status-bar__text {
   font-size: 14px;
   line-height: 18px;
   color: #8C9497;
}

.available-managers-status-bar__link {
   text-decoration: none;
}

.available-managers-status-bar__status-bar {
   height: 4px;
   border-radius: 12px;
   background-color: #E4E4E4;
}

.available-managers-status-bar__status-bar-state {
   height: 4px;
   border-radius: 12px;
}

.available-managers-status-bar__status-bar_low .available-managers-status-bar__status-bar-state {
   background-color: #43DB4A;
}

.available-managers-status-bar__status-bar_medium .available-managers-status-bar__status-bar-state {
   background-color: #FFCF40;
}

.available-managers-status-bar__status-bar_high .available-managers-status-bar__status-bar-state {
   background-color: #ED5656;
}

