.managers-number-input,
.managers-number-input__container  {
   display: flex;
   align-items: center;
   gap: 12px;
}

.managers-number-input {
   flex-wrap: wrap;
}

.managers-number-input.disabled {
   pointer-events: none;
}

.managers-number-input__icon {
   flex: 0 0 24px;
   width: 24px;
   height: 24px;
   margin: 0;
}

.managers-number-input.disabled .managers-number-input__icon path {
   fill: rgba(216, 217, 223, 1);
}

.managers-number-input__default-qty,
.managers-number-input__input-text {
   font-size: 16px;
   line-height: 1.25;
   color: #8C9497;
}

.managers-number-input.disabled .managers-number-input__default-qty,
.managers-number-input.disabled .managers-number-input__input-text {
   color: rgba(216, 217, 223, 1);
}

.managers-number-input__input {
   width: 56px;
   min-height: 30px;
   padding: 7px 15px;
   border: 1px solid #E4E4E4;
   border-radius: 12px;
   outline: none;
}

.managers-number-input.disabled .managers-number-input__input {
   border-color: rgba(236, 236, 236, 1);
   background-color: rgba(249, 249, 249, 1);
   color: rgba(216, 217, 223, 1);
}

.managers-number-input__input-text {
   color: #252525;
}