.status-select {
   position: relative;
}

.status-select__selected-value {
   width: 100%;
   min-height: 32px;
   min-width: 186px;
   padding: 6px 8px;
   border-radius: 24px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 4px;
   background-color: rgba(255, 148, 25, 1);
   font-size: 14px;
   line-height: 20px;
   color: white;
   cursor: pointer;
   transition: all 0.2s ease;
}

.status-select_disabled .status-select__selected-value {
   background-color: lightgray;
   cursor: default;
}

.status-select__icon {
   width: 16px;
   height: 8px;
   transform: rotate(180deg);
   transition: all 0.2s ease;
}

.status-select.active .status-select__icon {
   transform: rotate(0);
}

.status-select__icon path {
   fill: white;
}

.status-select__popup {
   position: absolute;
   z-index: 10;
   top: calc(100% + 4px);
   left: 0;
   min-width: 100%;
   max-width: 250px;
   padding: 8px;
   border-radius: 12px;
   background-color: white;
   box-shadow: -2px 8px 20px 0 rgba(30, 34, 40, 0.1);
   visibility: hidden;
   opacity: 0;
   transition: all 0.2s ease;
}

.status-select.active .status-select__popup {
   visibility: visible;
   opacity: 1;
}

.status-select__list {
   list-style: none;
   max-height: 216px;
   overflow-y: auto;
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.status-select__option {
   width: 100%;
   min-height: 40px;
   padding: 10px 12px;
   border-radius: 12px;
   display: flex;
   align-items: center;
   gap: 12px;
   transition: all 0.2s ease;
   cursor: pointer;
}

.status-select__option-text {
   font-size: 16px;
   line-height: 24px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.status-select__option.selected,
.status-select__option:hover {
   background-color: rgba(243, 243, 245, 1);
}