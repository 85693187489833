.contact-info {
   flex: 1 1 auto;
   height: 100vh;
   padding: 24px 16px 24px 0;
   display: flex;
   flex-direction: column;
   overflow: auto;
}

.contact-info-loader {
   height: 100%;
}

.contact-info-wrapper {
   height: 100%;
   padding: 24px 32px;
   border-radius: 16px;
   display: flex;
   flex-direction: column;
   gap: 24px;
   background-color: white;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
   overflow: hidden;
}

@media screen and (max-width: 900px) {
   .contact-info {
      padding: 0;
   }

   .contact-info-wrapper {
      border-radius: 0;
   }
}

.contact-info-header {
   display: flex;
   flex-direction: column;
   gap: 30px;
}

.contact-info-header_wrapper {
   display: flex;
   align-items: center;
   gap: 20px;
}

.contact-info-header_block {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.contact-info-header_avatar {
   position: relative;
   flex: 0 0 56px;
   width: 56px;
   height: 56px;
}

.contact-info-header_avatar-photo {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   object-fit: cover;
}

.contact-info-header_avatar-icon {
   position: absolute;
   right: 0;
   bottom: 0;
}

.contact-info-header_block-name {
   font-weight: 700;
   font-size: 20px;
   line-height: 28px;
}

.contact-info-header_block-go-to-dialogs.btn {
   min-height: 32px;
   padding: 0;
}

.contact-info__edit-btn {
   margin-left: auto;
   padding: 15px 20px;
   border-radius: 16px;
   font-size: 16px;
   line-height: 24px;
}

.contact-info__edit-btn svg path {
   fill: white;
}

.contact-info-page-block {
   padding: 0 30px 0 30px;
   display: flex;
   flex-direction: row;
}

.contact-info-control {
   max-width: 448px;
}

.contact-info-list {
   margin-bottom: 24px;
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.contact-button-block {
   display: flex;
   gap: 16px;
}

@media screen and (max-width: 1139.98px) {
   .contact-info-header_wrapper {
      gap: 10px;
   }

   .contact-button-block {
      flex-direction: column;
      align-items: flex-start;
   }

   .contact-info__edit-btn {
      padding: 11px 12px;
      font-size: 14px;
      line-height: 20px;
   }
}

@media screen and (max-width: 575.98px) {
   .contact-info__edit-btn {
      flex: 0 0 44px;
      width: 44px;
   }

   .contact-info__edit-btn span {
      display: none;
   }
}

.contact-info__btn {
   flex: 1 1 auto;
   min-height: 56px;
   padding: 15px 20px;
   border-radius: 16px;
   flex-direction: row-reverse;
   box-shadow: none;
   font-size: 16px;
   line-height: 24px;
}

.contact-info__btn_delete {
   border-color: transparent;
}

.contact-info__btn_delete:hover {
   border-color: transparent;
}

.contact-info__btn_delete svg {
   width: 14px;
   height: 16px;
   fill: rgba(237, 64, 60, 1);
}

.contact-info__btn_delete svg path {
   fill: rgba(237, 64, 60, 1);
}

.contact-info__btn_delete:hover svg path {
   fill: rgba(243, 101, 98, 1);
}

.contact-info__btn_delete:active svg path {
   fill: rgba(208, 42, 38, 1);
}

.contact-info__btn_delete:disabled svg path {
   fill: rgba(197, 199, 208, 1);
}

.contact-info__btn_archive,
.contact-info__btn_archive:hover {
   border-color: rgba(93, 96, 112, 1);
   color: rgba(93, 96, 112, 1);
}
