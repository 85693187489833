.dialog-card {
   border: 1px solid transparent;
   border-radius: 12px;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
   background-color: white;
   transition: all 0.2s ease;
   cursor: pointer;
}

.dialog-card:hover,
.dialog-card_selected {
   border-color: rgba(0, 172, 239, 1);
}

.dialog-card__header {
   padding: 8px 12px;
   border-top-left-radius: 12px;
   border-top-right-radius: 12px;
   display: flex;
   align-items: center;
   gap: 12px;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
   background-color: white;
}

.dialog-card:hover .dialog-card__header,
.dialog-card_selected .dialog-card__header {
   background-color: rgba(250, 250, 250, 1);
}

.dialog-card__time-block {
   align-self: stretch;
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.dialog-card__last-message-date {
   font-size: 12px;
   line-height: 16px;
   color: rgba(93, 96, 112, 1);
}

.dialog-card__new-messages-counter {
   align-self: flex-end;
   flex: 0 0 20px;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(0, 172, 239, 1);
   font-size: 11px;
   line-height: 16px;
   color: white;
   overflow: hidden;
   text-overflow: ellipsis;
}

.dialog-card__footer {
   padding: 8px 12px;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.dialog-card__footer-top {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   gap: 8px;
}

.dialog-card__status {
   padding: 4px 8px;
   border-radius: 24px;
   font-size: 12px;
   line-height: 16px;
   color: white;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.dialog-card__timer svg path {
   fill: rgba(93, 96, 112, 1);
}

.dialog-card__tags {
   display: flex;
   gap: 8px;
}

.dialog-card__tags-list {
   flex: 1 1 auto;
   display: flex;
   flex-wrap: wrap;
   gap: 8px;
   max-height: 24px;
   overflow: hidden;
}

.dialog-card__tags.active .dialog-card__tags-list {
   max-height: 56px;
   overflow: auto;
}

.dialog-card__tags.active .dialog-card__tags-list::-webkit-scrollbar {
   display: block;
   width: 2px;
}

.dialog-card__tags.active .dialog-card__tags-list::-webkit-scrollbar-thumb {
   background-color: rgba(26, 115, 232, 1);
}

.dialog-card__tags.active .dialog-card__tags-list::-webkit-scrollbar-track {
   background-color: rgba(26, 115, 232, 0.15);
}

.dialog-card__tags-more-btn {
   flex: 0 0 auto;
   display: flex;
   align-items: center;
   gap: 1px;
   cursor: pointer;
}

.dialog-card__tags.active .dialog-card__tags-more-btn {
   align-self: flex-start;
}

.dialog-card__tags-more-btn-text {
   padding: 3px 12px;
   color: rgba(26, 115, 232, 1);
}

.dialog-card__tags-more-btn:hover .dialog-card__tags-more-btn-text {
   text-decoration: underline;
}

.dialog-card__tags-more-btn-icon {
   width: 11px;
   height: 6px;
   transform: rotate(180deg);
   transition: all 0.2s ease;
}

.dialog-card__tags.active .dialog-card__tags-more-btn-icon {
   transform: rotate(0);
}

.dialog-card__tags-more-btn-icon path {
   fill: #8C9497;
}