.mainbar-chat {
   position: relative;
   z-index: 10;
   padding: 16px 32px;
   border-top: 1px solid rgba(209, 210, 214, 1);
   display: flex;
   flex-direction: column;
   gap: 10px;
}

@media screen and (max-width: 767.98px) {
   .mainbar-chat {
      padding: 16px;
   }
}

.mainbar-chat-area-wrap {
   position: relative;
   width: 100%;
   height: 100%;
}

.mainbar-chat-wrapper {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.mainbar-chat-wrapper__send-message-form {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 24px;
}

@media screen and (max-width: 767.98px) {
   .mainbar-chat-wrapper__send-message-form {
      gap: 12px;
   }
}

.mainbar-chat-left-control-buttons {
   display: flex;
   width: 100px;
   justify-content: space-between;
}

.mainbar-chat-clip {
   cursor: pointer;
   display: inline;
   position: absolute;
   top: 14px;
   left: 14px;
   z-index: 999;
}

.mainbar-chat-clip:disabled {
   opacity: .2;
}

.file-quantity {
   position: absolute;
   top: 16px;
   left: 8px;
   background-color: #4bade9;
   border-radius: 50%;
   height: 15px;
   width: 15px;
   text-align: center;
   display: flex;
   justify-content: center;
   align-content: center;
}

.file-quantity_number {
   font-size: 10px;
   color: white;
}

.recorder-button {
   flex: 0 0 48px;
   width: 48px;
   height: 48px;
   padding: 0;
   border-radius: 16px;
   box-shadow: 0 0px 5px 0 rgba(108, 197, 222, 0.2);
}

.mainbar-chat-area-input-wrap {
   min-height: 48px;
   padding: 12px 80px 12px 40px;
   border-radius: 8px;
   width: 100%;
   display: flex;
   align-items: center;
   background-color: rgba(242, 243, 245, 1);
   overflow: hidden;
   text-overflow: ellipsis;
}

.mainbar-chat-area {
   width: 100%;
   max-height: 90px;
   background-color: transparent;
   border: none;
   outline: none;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   resize: none;
   color: #7f808c;
   line-height: 15px;
}

.mainbar-chat-area::placeholder {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.mainbar-chat-area:disabled::placeholder {
   opacity: .2;
}

.mainbar-chat-textarea::-webkit-scrollbar {
   display: none;
}

.mainbar-chat-control-buttons {
   display: flex;
   align-items: center;
   gap: 24px;
}

@media screen and (max-width: 767.98px) {
   .mainbar-chat-control-buttons {
      gap: 12px;
   }
}

.mainbar-chat-template-button {
   width: 32px;
   height: 32px;
   border-radius: 6px;
   background-color: #00acef;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   border: none;
}

.mainbar-chat-send-button.btn {
   width: 48px;
   height: 48px;
   border-radius: 16px;
   padding: 0;
}

.mainbar-chat-send-button:disabled {
   background-color: #E8E8E8;
}

.mainbar-chat-action-container {
   position: absolute;
}

.mainbar-chat-action-container_templates {
   right: 16px;
   top: 14px;
}

.mainbar-chat-action-container__btn {
   position: relative;
}

.mainbar-chat-action-container__tooltip {
   bottom: 0;
   left: 50%;
   transform: translate(-50%, -40px);
}

.mainbar-chat-action-container__btn button:hover ~ .mainbar-chat-action-container__tooltip {
   visibility: visible;
   opacity: 1;
}

.mainbar-chat-emoji,
.mainbar-chat-templates {
   cursor: pointer;
   height: 24px;
   width: 24px;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: transparent;
}

.mainbar-chat-emoji {
   position: absolute;
   right: 50px;
   top: 11px;
}

.mainbar-chat-emoji:disabled,
.mainbar-chat-templates:disabled {
  opacity: .2;
}

.mainbar-chat-emoji svg {
   stroke: #5d6070;
}

.mainbar-chat-templates svg path {
   fill: rgba(93, 96, 112, 1);
}

.mainbar-chat-control-buttons,
.mainbar-chat-template-button {
   cursor: pointer;
}

.dialog-chat-timer {
   position: absolute;
   bottom: calc(100% + 12px);
   left: 50%;
   transform: translateX(-50%);
}

@media (max-width: 900px) {
   .mainbar-chat {
      width: 100% !important;
   }
}