.tags-status-settings {
   overflow: auto;
}

.tags-status-settings_wrapper {
   padding: 70px;
}

.tags-status-settings_block {
   display: flex;
   flex-direction: column;
   gap: 27px;
}

.tags-status-settings_block-active {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 28px;
}

.tags-status-settings__add-btn {
   align-self: flex-start;
}

.tags-status-settings_item {
   display: flex;
   flex-direction: column;
   margin-bottom: 46px;
}

.tags-status-settings_title {
   font-size: 28px;
   line-height: 34px;
   font-weight: 700;
   color: #252525;
}

.tags-status-settings_button {
   width: 181px;
   /*width: 100%;*/
   height: 47px;
   box-shadow: 0 6px 20px rgba(108, 197, 222, 0.2);
   border-radius: 6px;
   border: 0 solid transparent;
   background: #40b7d9;
   /*font-family: 'Inter';*/
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #ffffff;
   cursor: pointer;
}

.tags-status-settings_button:hover {
   background-color: #4fc6e8;
}

.tags-status-settings_button:active {
   background-color: #39b0d2;
}

.tags-status-settings_button-active {
   border: none;
   height: 18px;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #40b7d9;
   background-color: #ffffff;
   cursor: pointer;
}

.tags-status-settings_button:disabled {
   background-color: #b8c5c9;
   box-shadow: 0 6px 18px rgba(186, 188, 189, 0.2);
}

.tags-status-settings_subtitle {
   margin-top: 24px;
   color: #252525;
   opacity: 0.4;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
}

.tags-status-settings_list {
   width: 100%;
   margin-bottom: 26px;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
   grid-auto-flow: column;
   grid-gap: 20px 10px;
}

.tags-status-settings_list-basic {
   width: 100%;
   height: 270px;
   display: flex;
   flex-wrap: wrap;
   flex-direction: column;
   align-content: space-between;
   row-gap: 20px;
   column-gap: 100px;
   margin-bottom: 26px;
}

.tag-item {
   list-style: none;
}

.tags-status-settings_pagination {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 6px;
   justify-content: flex-end;
}

.tags-status-settings_pagination-array {
   background: #ffffff;
   border: none;
   width: 18px;
   height: 18px;
   cursor: pointer;
}

.tags-status-settings_pagination-array svg path {
   fill: rgba(93, 96, 112, 1);
}

.tags-status-settings_pagination-button {
   width: 24px;
   height: 24px;
   background: #ffffff;
   border-radius: 50%;
   border: none;
   color: #252525;
   cursor: pointer;
}

.tags-status-settings_pagination-button-active {
   width: 24px;
   height: 24px;
   background: rgba(0, 172, 239, 1);
   border-radius: 50%;
   border: none;
   color: #ffffff;
   cursor: pointer;
}

@media (max-width: 900px) {
   .tags-status-settings_wrapper {
      padding: 15px 50px 30px;
   }
}
