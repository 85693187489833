.contact-card {
   min-height: 40px;
   padding: 7px 12px;
   border: 1px solid transparent;
   border-radius: 12px;
   display: flex;
   align-items: center;
   gap: 12px;
   background-color: white;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
   transition: all 0.2s ease;
}

.contact-card.selected,
.contact-card:hover {
   border-color: rgba(0, 172, 239, 1);
   background-color: rgba(240, 251, 255, 1);
   color: rgba(0, 172, 239, 1);
   cursor: pointer;
}

.contact-card__avatar {
   position: relative;
   flex: 0 0 32px;
   width: 32px;
   height: 32px;
}

.contact-card__avatar-photo {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   object-fit: cover;
}

.contact-card__channel-icon {
   position: absolute;
   bottom: 0;
   right: 0;
   width: 12px;
   height: 12px;

}
.contact-card__username {
   font-size: 16px;
   line-height: 20px;
   color: rgba(30, 34, 40, 1);
   transition: all 0.2s ease;
}

.contact-card.selected .contact-card__username,
.contact-card:hover .contact-card__username {
   color: rgba(0, 172, 239, 1);
}
