.cart-details {
   gap: 12px;
}

.cart-details-header {
   padding-bottom: 12px;
   border-bottom: 1px solid #E4E4E4;
   display: flex;
   flex-direction: column;
}

.cart-details-header__text {
   font-size: 12px;
   line-height: 1.3;
}

.cart-details-header__price {
   font-size: 20px;
   line-height: 1.2;
   color: #00ACEF;
}

.cart-details-body {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.cart-details-body__toggle {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
   font-weight: 700;
   font-size: 14px;
   line-height: 1.285;
   cursor: pointer;
}

.cart-details-body__toggle-icon {
   width: 14px;
   margin: 0;
   transform: rotate(180deg);
   transition: all 0.3s ease;
}

.cart-details-body.active .cart-details-body__toggle-icon {
   transform: rotate(0);
}

.cart-details-body__list {
   display: flex;
   flex-direction: column;
   gap: 12px;
}

.cart-details-body__item {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
}

.cart-details-body__item-name,
.cart-details-body__item-price {
   font-size: 14;
   line-height: 1.285;
}

.cart-details-body__item-name span {
   color: #8C9497;
}

.cart-details-toggle .toggle-with-text__container {
   background-color: #00ACEF;
}

.cart-details-toggle .toggle-with-text__toggle_active,
.cart-details-toggle .toggle-with-text__toggle_inactive {
   background-color: white;
}

.cart-details-toggle .toggle-with-text__text {
   font-size: 12px;
   line-height: 1.3;
   color: #8C9497;
}