.add-contact-form {
   display: flex;
   flex-direction: column;
   gap: 24px;
}

.add-contact-form__status {

}

.add-contact-form__body {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.add-contact-form__control {
   padding: 12px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   gap: 4px;
   background-color: rgba(242, 243, 245, 1);
}

.add-contact-form__control-error {
   display: none;
   font-size: 12px;
   line-height: 16px;
   color: #ED5656;
}

.add-contact-form__control.error .add-contact-form__control-error {
   display: block;
}

.add-contact-form__input {
   width: 100%;
   min-height: 24px;
   border: none;
   background-color: transparent;
   outline: none;
}

.add-contact-form__input,
.add-contact-form__input::placeholder,
.add-contact-form__textarea {
   font-size: 16px;
   line-height: 24px;
   color: rgba(93, 96, 112, 1);
}

.add-contact-form__textarea {
   height: 72px;
   border: none;
   background-color: transparent;
   resize: none;
   outline: none;
}

.add-contact-form__footer {
   display: flex;
   justify-content: flex-end;
   gap: 20px;
}

.add-contact-form__btn.btn {
   min-height: 48px;
   border-radius: 12px;
   box-shadow: none;
}

.add-contact-form-select {
   padding: 8px;
   border-radius: 12px;
   background-color: rgba(242, 243, 245, 1);
}

.add-contact-form-select.active {
   background-color: white;
   box-shadow: -2px 8px 20px 0 rgba(30, 34, 40, 0.1);
}

.add-contact-form-select__toggle {
   width: 100%;
   min-height: 32px;
   padding: 4px 8px;
   border-radius: 8px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
   background-color: rgba(242, 243, 245, 1);
   cursor: pointer;
}

.add-contact-form-select__error {
   padding: 0 4px;
   margin-bottom: 4px;
   font-size: 12px;
   line-height: 16px;
   color: #ED5656;
}

.add-contact-form-select__selected-value {
   flex: 1 1 auto;
   font-size: 16px;
   line-height: 24px;
   color: rgba(93, 96, 112, 1);
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   text-align: left;
}

.add-contact-form-select__icon {
   flex: 0 0 auto;
   width: 13px;
   height: 7px;
   transform: rotate(180deg);
   transition: all 0.2s ease;
}

.add-contact-form-select.active .add-contact-form-select__icon {
   transform: rotate(0);
}

.add-contact-form-select__icon path {
   fill: rgba(93, 96, 112, 1);
}

.add-contact-form-select__popup {
   display: none;
   flex-direction: column;
   gap: 4px;
}

.add-contact-form-select.active .add-contact-form-select__popup {
   display: flex;
}

.add-contact-form-select__list-title {
   padding: 12px;
   padding-bottom: 0;
   font-size: 12px;
   line-height: 16px;
   color: rgba(93, 96, 112, 1);
}

.add-contact-form-select__list {
   max-height: 128px;
   display: flex;
   flex-direction: column;
   gap: 4px;
   overflow-y: auto;
}

.add-contact-form-select__list-item {
   min-height: 40px;
   padding: 8px 12px;
   border-radius: 8px;
   font-size: 16px;
   line-height: 24px;
   color: black;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   transition: all 0.2s ease;
}

.add-contact-form-select__list-item:hover {
   cursor: pointer;
   background-color: rgba(242, 243, 245, 1);
}