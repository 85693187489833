.last-message {
   flex: 1 1 auto;
   overflow: hidden;
}

.last-message__name {
   font-weight: 600;
   font-size: 16px;
   line-height: 1.25;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.last-message__product-name {
   font-size: 14px;
   line-height: 1.25;
   color: #40B7D9;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.last-message__text {
   font-size: 14px;
   line-height: 20px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   color: rgba(93, 96, 112, 1);
}