.radio {
   flex: 0 0 20px;
   width: 20px;
   height: 20px;
   border: 1px solid rgba(228, 229, 231, 1);
   border-radius: 50%;
}

.radio_active {
   border-width: 4px;
   border-color: rgba(0, 172, 239, 1);
}