.navbar {
   flex: 0 1 92px;
   height: 100vh;
   padding: 24px 0 24px 16px;
}

.navbar-logo-block {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 12px;
}

.navbar-logo {
   margin: 0;
}

.navbar-toggle {
   flex: 0 0 44px;
   width: 44px;
   height: 44px;
   border: 1px solid rgba(0, 172, 239, 1);
   border-radius: 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   transition: all 0.2s ease;
}

.navbar-toggle:hover {
   background-color: rgba(0, 172, 239, 0.05);
}

.navbar-toggle svg {
   transform: rotate(180deg);
   transition: all 0.2s ease;
}

.navbar.active .navbar-toggle svg {
   transform: rotate(0);
}

.navbar-toggle svg path {
   fill: rgba(0, 172, 239, 1);
}

.navbar-wrapper {
   height: 100%;
   padding: 32px 16px 24px;
   border-radius: 16px;
   background-color: white;
   box-shadow: -2px 4px 16px 0 rgba(30, 34, 40, 0.05);
}

.navbar-container {
   max-width: 44px;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 40px;
   overflow: hidden;
   transition: all 0.2s ease;
}

.navbar.active .navbar-container {
   transform: rotate(0);
   max-width: 161px;
}

.navbar-view-bottom-button {
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.navbar-view-toggle-buttons {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
   gap: 16px;
   color: #ffffffab;
}

.navbar-header-logout-link {
   color: #fff;
   text-align: center;
   width: 100%;
   cursor: pointer;
}

.navbar-icon-section {
   flex: 0 0 44px;
   min-height: 44px;
   padding: 8px 12px;
   border-radius: 12px;
   display: flex;
   align-items: center;
   gap: 12px;
   transition: all 0.2s ease;
}

.navlink.active .navbar-icon-section,
.navlink:hover .navbar-icon-section {
   background-color: rgba(255, 94, 45, 0.05);
}

.navlink-icon {
   flex: 0 0 20px;
   width: 20px;
   height: 20px;
}

.navlink-icon path {
   fill: rgba(93, 96, 112, 1);
   transition: all 0.2s ease;
}

.navlink.active .navlink-icon path,
.navlink:hover .navlink-icon path {
   fill: rgba(255, 94, 45, 1);
}

.navlink-text {
   font-size: 16px;
   line-height: 24px;
   color: rgba(93, 96, 112, 1);
   transition: all 0.2s ease;
}

.navlink.active .navlink-text,
.navlink:hover .navlink-text {
   color: rgba(255, 94, 45, 1);
}

@media screen and (max-width: 575.98px) {
   .navbar {
      flex: 0 1 66px;
      padding: 12px 0 12px 8px;
   }

   .navbar-wrapper {
      padding: 32px 8px 24px;
   }
}

@media screen and (max-width: 425.98px) {
   .navbar {
      flex: 0 1 58px;
      padding: 0;
      position: relative;
      z-index: 100;
   }

   .navbar-wrapper {
      border-radius: 0;
   }
}

.navlink {
   display: block;
   position: relative;
   cursor: pointer;
   text-decoration: none;
}

.navlink__tooltip {
   top: 50%;
   left: 100%;
   transform: translate(35px, -50%);
}

@media screen and (max-width: 575.98px) {
   .navlink__tooltip {
      transform: translate(25px, -50%);
   }
}

.navlink:hover .navlink__tooltip {
   visibility: visible;
   opacity: 1;
}